#homeslider {
  .__item-index {
    .ms-slide-img {
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      height: 650px;
      position: relative;
      &:after {
        content: " ";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #202020;
        opacity: 0.5;
      }
    }
  }
}
