/*==================== Media Queries ====================*/

/*==================== Media Query for max-width 1440px (Laptop View) ====================*/

@media (max-width:1440px) {
    /*==================== 2. Homepage CSS ====================*/
    /*---------- Homepage Slider Section ----------*/
    #ms-slider-section .ms-slider-pad {
        padding-left: 10%;
        padding-right: 10%;
    }
    #ms-slider-section .carousel-caption {
        bottom: 60px;
    }
    #ms-slider-section .carousel-indicators {
        bottom: 260px;
    }
    /*---------- Homepage Testimonials Section ----------*/
    .ms-testimonial-home .ms-testimonial-box:after {
        left: 210px !important;
    }
    .ms-testimonial-home #customers-testimonials .owl-nav button {
        top: 160px;
    }
    .ms-testimonial-home .ms-testimonial-box {
        padding: 40px 20px 30px 20px !important;
    }

}

/*==================== Media Query for max-width 1300px (Laptop View) ====================*/

@media (max-width:1300px) {
    /*==================== 2. Homepage CSS ====================*/
    /*---------- Homepage Slider Section ----------*/
    #ms-slider-section .ms-slider-pad {
        padding-left: 0;
        padding-right: 0;
    }
    #ms-slider-section .carousel-indicators {
        margin-right: 0;
    }
    #ms-slider-section .ms-spacer-60 {
        height: 30px;
    }
    /*---------- Homepage Testimonials Section ----------*/
    .ms-testimonial-home .ms-testimonial-box:after {
        left: 185px !important;
    }

}

/*==================== Media Query for max-width 1030px (Ipad Pro View) ====================*/

@media (max-width:1030px) {
    /*==================== 1. General CSS ====================*/
    h1 {
        font-size: 60px;
    }

    /*========== Header Section ==========*/
    header .ms-logo-pad {
        padding: 25px 10px 25px 10px;
    }
    header .ms-header-main ul li a.nav-link {
        margin: 0 5px;
    }

    /*==================== 2. Homepage CSS ====================*/
    /*---------- Homepage Services Section ----------*/
    #ms-service-section .ms-left-image-bg {
        padding: 120px 50px;
    }
    /*---------- Homepage Specialize Section ----------*/
    #ms-special-section .ms-img-content {
        bottom: 10px;
        left: 22px;
    }
    /*---------- Homepage Testimonials Section ----------*/
    .ms-testimonial-home .ms-testimonial-box:after {
        left: 225px !important;
    }
    .ms-testimonial-home #customers-testimonials .owl-nav .owl-prev {
        left: 7%;
    }
    .ms-testimonial-home #customers-testimonials .owl-nav .owl-next {
        right: 7%;
    }

    /*==================== 4. About Page CSS ====================*/
    /*---------- About Content Section ----------*/
    #ms-abt-section .col-md-7.ms-pad-right {
        padding-right: 30px;
    }
    #ms-abt-section .col-md-7.ms-pad-left {
        padding-left: 30px;
    }

    /*==================== 5. Testimonials Page CSS ====================*/
    #ms-testimonial-section .ms-testimonial-box {
        padding: 40px 40px 20px 40px;
    }
    #ms-testimonial-section .ms-spacer-30 {
        height: 10px;
    }
    #ms-testimonial-section .ms-testimonial-box:after {
        left: 202px;
    }

    /*==================== 9. Shop Detail Page CSS ====================*/
    #ms-shop-detail-section .carousel-item .thumb {
        width: 31.2%;
    }

    /*==================== 11. Team Page CSS ====================*/
    /*---------- Our Team Page Content ----------*/
    #ms-team-page-section .ms-team-box {
        padding: 60px;
    }

    /*==================== 12. Service V1 Page CSS, Service V2 Page CSS and Service Details Page CSS ====================*/
    /*---------- Service Page Tab Section ----------*/
    #ms-service-tab-section .tab-content {
        padding-left: 30px;
    }

    /*---------- Brands We Work With Section ----------*/
    .ms-brands-section .ms-sec-padding .row img {
        padding: 20px;
    }

}

/*==================== Media Query for max-width 770px (Ipad View) ====================*/

@media(max-width:770px) {
    /*==================== 1. General CSS ====================*/
    h1 {
        font-size: 50px;
    }
    h2 {
        font-size:26px;
    }
    h4 {
        font-size:20px;
    }
    p {
        font-size: 16px;
    }
    .ms-primary-btn {
        padding: 11px 40px;
    }
    .ms-sec-padding {
        padding:80px 0;
    }
    .ms-ipad-margin {
        margin-bottom: 30px;
    }
    .ms-special-footer-pad {
        padding-bottom: 130px;
    }

    /*========== Header Section ==========*/
    header .ms-logo-pad {
        padding: 23px 15px 23px 15px;
    }
    header .ms-header-top {
        padding: 15px 30px;
    }
    header .ms-header-main  .menu_toggle {
        left: 390px;
    }
    header .ms-skew-bg, header .ms-header-top p, header .ms-header-top img, header .ms-header-main ul li a, .navbar-toggler.collapsed, header nav ul li {
        transform: skew(0deg);
        -webkit-transform: skew(0deg);
    }

    /*========== Footer Section ==========*/
    #ms-footer .ms-footer-top .ms-foot-btn {
        padding: 13px 25px;
        font-size: 17px;
    }
    #ms-footer .ms-footer-top input {
        padding: 15px 150px 15px 20px;
    }
    #ms-footer .ms-footer-bottom .ms-bg-orange {
        padding: 20px 20px 20px 20px;
    }
    #ms-footer .ms-footer-bottom .col-md-2 .fa-2x {
        font-size: 24px !important;
    }
    #ms-footer .ms-spacer-120 {
        height: 90px;
    }
    #ms-footer .ms-newsletter-box {
        padding: 25px 20px;
    }
    #ms-footer .ms-newsletter-box h3 {
        font-size:20px;
    }
    #ms-footer .ms-newsletter-box {
        margin-top: -150px;
    }

    /*==================== 2. Homepage CSS ====================*/
    /*---------- Homepage Slider Section ----------*/
    #ms-slider-section .carousel-caption {
        bottom: 100px;
    }
    #ms-slider-section .ms-slider-indicator {
        z-index: 1;
    }
    #ms-slider-section .ms-primary-btn {
        padding: 10px 40px;
    }
    /*---------- Homepage Service Section ----------*/
    #ms-service-section .ms-left-image-bg {
        padding: 80px 10px;
    }
    #ms-service-section .ms-sec-padding {
        padding-left: 20px;
    }
    /*---------- Homepage About Us Section ----------*/
    #ms-about-section .ms-contact-box {
        padding: 20px 15px;
    }
    /*---------- Homepage Team Section ----------*/
    #ms-team-section .ms-team-box .ms-team-img {
        padding-left: 35px;
        padding-right: 35px;
    }
    #ms-team-section .ms-team-box img.position-absolute {
        top: 33%;
        right: 12%;
    }
    /*---------- Homepage Testimonials Section ----------*/
    .ms-testimonial-home .ms-testimonial-box:after {
        left: 160px !important;
    }
    .ms-testimonial-home .ms-testimonial-box {
        padding: 40px 10px 20px 10px !important;
    }
    /*---------- Homepage Blog Section ----------*/
    #ms-blog-section {
        padding-bottom: 120px;
    }

    /*==================== 4. About Page CSS ====================*/
    /*---------- About Content Section ----------*/
    #ms-abt-section .col-md-7.ms-pad-right {
        padding-right: 10px;
    }
    #ms-abt-section .col-md-7.ms-pad-left {
        padding-left: 10px;
    }
    #ms-abt-section .ms-spacer-100 {
        height: 70px;
    }

    /*==================== 5. Testimonials Page CSS ====================*/
    #ms-testimonial-section .ms-testimonial-box:after {
        left: 142px;
    }

    /*==================== 7. 404 Error Page CSS ====================*/
    /*---------- 404 Page Section ----------*/
    #ms-404-section {
        padding: 100px 0 150px 0;
    }
    #ms-404-section h1 {
        font-size: 40px;
    }

    /*==================== 11. Team Page CSS ====================*/
    /*---------- Our Team Page Content ----------*/
    #ms-team-page-section .ms-team-box {
        padding: 40px;
    }

    /*==================== 12. Service V1 Page CSS, Service V2 Page CSS and Service Details Page CSS ====================*/
    /*---------- Service Page Tab Section ----------*/
    #ms-service-tab-section .tab-content {
        padding-left: 0;
    }

    #ms-service-section .ms-sidebar-img {
        display: none;
    }
    .carousel-caption .ms-font-32 {
        font-size: 20px;
    }

    #ms-about-section {
        background-image: none;
    }

}

/*==================== Media Query for max-width 500px, 380px, 350px (Mobile View) ====================*/

@media (max-width:500px) {
    /*==================== 1. General CSS ====================*/
    h1 {
        font-size: 30px;
    }
    .ms-sec-padding {
        padding: 60px 0;
    }
    .ms-special-footer-pad {
        padding-bottom: 140px;
    }

    /*========== Header Section ==========*/
    header .ms-header-main .menu_toggle {
        left: 325px;
    }

    /*========== Footer Section ==========*/
    #ms-footer .ms-newsletter-box {
        padding: 20px 20px;
        margin-top: -170px;
    }
    #ms-footer .ms-spacer-120 {
        height: 60px;
    }
    #ms-footer .ms-footer-bottom {
        padding: 20px 0;
    }
    #ms-footer .ms-footer-bottom .col-md-4 .position-relative {
        top: 5px;
    }

    /*==================== 2. Homepage CSS ====================*/
    /*---------- Homepage Slider Section ----------*/
    #ms-slider-section .ms-font-21 {
        font-size: 16px;
    }
    #ms-slider-section .ms-spacer-60 {
        height: 10px;
    }
    #ms-slider-section .carousel-indicators {
        bottom: 20px;
        left: -12%;
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
    }
    #ms-slider-section .carousel-caption {
        bottom: 120px;
        right: 4%;
        left: 4%;
    }
    #ms-slider-section .carousel-inner img.ms-slide-img {
        object-position: right;
    }
    /*---------- Homepage Services Section ----------*/
    #ms-service-section .ms-left-image-bg {
        padding: 60px 20px;
    }
    #ms-service-section .ms-sec-padding {
        padding-left: 0px;
    }
    #ms-service-section .ms-service-box {
        margin-bottom: 15px;
    }
    /*---------- Homepage About Us Section ----------*/
    #ms-about-section .ms-contact-box.position-relative {
        top: 0px;
    }
    #ms-about-section {
        padding-bottom: 100px;
    }
    /*---------- Homepage Team Section ----------*/
    #ms-team-section .ms-team-box img.position-absolute {
        top: 43%;
        right: 25%;
    }
    /*---------- Homepage Specialize Section ----------*/
    #ms-special-section .ms-img-content {
        bottom: 20px;
        left: 32px;
        right: 40px;;
    }
    /*---------- Homepage Testimonials Section ----------*/
    .ms-testimonial-home .ms-testimonial-box:after {
        left: 170px !important;
    }
    .ms-testimonial-home #customers-testimonials .owl-nav button {
        top: 310px;
    }
    /*---------- Homepage Blog Section ----------*/
    #ms-blog-section .ms-width-100 {
        max-width: 100% !important;
        flex: 0 0 100% !important;
    }
    #ms-blog-section .ms-blog-box {
        padding: 10px 0 20px 0;
    }
    #ms-blog-section {
        padding-bottom: 100px;
    }

    /*==================== 3. Inner Page's Banner CSS ====================*/
    #ms-banner-section {
        padding: 200px 0 90px 0;
    }
    #ms-banner-section h1, #ms-faq-section h1 {
        font-size: 40px;
    }

    /*==================== 4. About Page CSS ====================*/
    /*---------- About Content Section ----------*/
    #ms-abt-section .col-md-7.ms-pad-right {
        padding-right: 15px;
    }
    #ms-abt-section .col-md-7.ms-pad-left {
        padding-left: 15px;
    }
    #ms-abt-section .ms-spacer-100 {
        height: 40px;
    }
    /*---------- Counter Section ----------*/
    #ms-counter-section h1 {
        font-size: 60px;
    }

    /*==================== 5. Testimonials Page CSS ====================*/
    #ms-testimonial-section {
        padding-bottom: 100px;
    }
    #ms-testimonial-section .ms-testimonial-box {
        margin-bottom: 35px;
    }
    #ms-testimonial-section .ms-testimonial-box:after {
        left: 170px;
    }

    /*==================== 6. FAQ Page CSS ====================*/
    #ms-faq-section {
        padding-bottom: 110px;
    }

    /*==================== 7. 404 Error Page CSS ====================*/
    /*---------- 404 Page Section ----------*/
    #ms-404-section {
        padding: 100px 0 180px 0;
    }
    #ms-404-section h1 {
        font-size: 30px;
    }

    /*==================== 11. Team Page CSS ====================*/
    /*---------- Our Team Page Content ----------*/
    #ms-team-page-section .ms-team-box {
        padding: 30px;
    }

    /*==================== 12. Service V1 Page CSS, Service V2 Page CSS and Service Details Page CSS ====================*/
    /*---------- Service Page Tab Section ----------*/
    .ms-brands-section .ms-sec-padding {
        padding-bottom: 100px;
    }

    /*==================== 14. Cart Page CSS ====================*/
    /*---------- Cart Page Content ----------*/
    #ms-cart-section .ms-number input {
        font-size: 18px;
    }
    #ms-service-section .ms-left-image-bg {
        background-size: 250px;
    }
}

@media (max-width:380px) {
    #ms-service-section .ms-left-image-bg {
        background-size: 0px;
    }
    /*==================== 1. General CSS ====================*/
    /*========== Header Section ==========*/
    header .ms-header-main .menu_toggle {
        left: 285px;
    }

    /*==================== 2. Homepage CSS ====================*/
    /*---------- Homepage Testimonials Section ----------*/
    .ms-testimonial-home .ms-testimonial-box:after {
        left: 150px !important;
    }

    /*==================== 5. Testimonials Page CSS ====================*/
    #ms-testimonial-section .ms-testimonial-box:after {
        left: 155px;
    }

    /*==================== 14. Cart Page CSS ====================*/
    /*---------- Cart Page Content ----------*/
    #ms-cart-section .ms-btn-dark {
        padding: 14px 30px;
    }

}

@media (max-width:350px) {
    /*==================== 1. General CSS ====================*/
    /*========== Header Section ==========*/
    header .ms-header-main .menu_toggle {
        left: 250px;
    }
    header .ms-header-top {
        padding: 15px 10px;
    }

    /*==================== 2. Homepage CSS ====================*/
    /*---------- Homepage Testimonials Section ----------*/
    .ms-testimonial-home .ms-testimonial-box:after {
        left: 125px !important;
    }
    .ms-testimonial-home #customers-testimonials .owl-nav .owl-prev {
        left: 0;
    }
    .ms-testimonial-home #customers-testimonials .owl-nav .owl-next {
        right: 0;
    }
    .ms-testimonial-home #customers-testimonials .owl-nav button {
        top: 335px;
    }

    /*==================== 5. Testimonials Page CSS ====================*/
    #ms-testimonial-section .ms-testimonial-box:after {
        left: 122px;
    }

    /*==================== 14. Cart Page CSS ====================*/
    /*---------- Cart Page Content ----------*/
    #ms-cart-section .ms-btn-dark {
        padding: 14px 20px;
    }

}


/*==================== Media Query from 1860px to 2000px ====================*/

@media (min-width:1860px) and (max-width:2000px) {
    /*==================== 2. Homepage CSS ====================*/
    /*---------- Homepage Services Section ----------*/
    #ms-service-section .ms-sidebar-img {
        display: block;
    }
}

/*==================== Media Query for min width 991px ====================*/

@media (min-width: 991px) {
    /*==================== 1. General CSS ====================*/
    /*========== Header Section ==========*/
    header .ms-header-main .ms-header-area .ms-header-content .navbar-nav li.dropdown .dropdown-menu {
        display: block;
        min-width: 150px;
    }
    #ms-service-section .ms-sidebar-img {
        display: none;
    }
}

/*==================== Media Query for min width 700px and max width 800px ====================*/

@media (min-width:700px) and (max-width:800px) {
    /*==================== 2. Homepage CSS ====================*/
    /*---------- Homepage Need Help Section ----------*/
    #ms-help-section .col-md-6.position-relative {
        left: 25%;
    }
}
